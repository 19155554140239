<script>
import WorkflowCard from '@comp/projectSettings/step3Documents/WorkflowCard.vue'
import { WORKFLOWS, WORKFLOW_STEPS } from '@src/utils/consts'

export default {
  name: 'WorkflowsSection',
  components: {
    WorkflowCard,
  },
  props: {
    workflowTemplates: {
      type: Array,
      required: true,
    },
    labelTypes: {
      type: Object,
      required: true,
    },
    activeMetadata: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'changes',
    'update:workflowTemplates',
    'order:workflowTemplates',
  ],
  data () {
    return {
      customLabelsDialogNewLabel: null,
      admissibility_default_labels: [
        { name: 'Admissible', isActive: true, isAdmissible: true },
        { name: 'Not admissible', isActive: true, isAdmissible: false },
        { name: 'Not applicable', isActive: true, isAdmissible: false },
      ],
      pm_evaluation_default_labels: [
        { name: 'VSO - Visa sans observation', isActive: true },
        { name: 'VAC - Visa avec commentaires', isActive: true },
        { name: 'VAO - Visa avec observation', isActive: true },
        { name: 'REF - Refusé', isActive: true },
        { name: 'VNR - Visa non requis', isActive: true },
        { name: 'BPD - Bon pour DOE', isActive: true },
      ],
      summary_state_default_labels: [
        { name: 'Bon pour passage BPE', isActive: true },
        { name: 'Pas bon pour passage BPE', isActive: true },
        { name: 'Avis non requis', isActive: true },
        { name: 'BPE', isActive: true },
        { name: 'NOK CAE', isActive: true },
        { name: 'OK CAE', isActive: true },
      ],
    }
  },
  computed: {
    workflows () {
      return Object.values(WORKFLOWS).map(wf => ({ value: wf.name, text: wf.label }))
    },
  },
  watch: {
    workflowTemplates: {
      handler(newVal) {
        this.$emit('update:workflowTemplates', newVal)
      },
      deep: true,
    },
  },
  methods: {
    addWF () {
      this.workflowTemplates.push({
        _created: true,
        id: 'tmp_' + Math.random().toString(36).substr(2, 9),
        name: null,
        steps: [],
        labels: {
          admissibilityList: this.admissibility_default_labels,
          pmEvaluationList: this.pm_evaluation_default_labels,
          summaryStateList: this.summary_state_default_labels,
        },
        metadataName: null,
        metadataValues: [],
        sequence: this.workflowTemplates.length,
      })
    },
    changeWorkflowTemplateType (value, workflowTemplate) {
      const allSteps = Object.keys(WORKFLOW_STEPS).map(o => ({ label: WORKFLOW_STEPS[o].label, type: o }))
      const newSteps = allSteps.map(o => ({
        label: o.label,
        type: o.type,
        days0: 0,
        daysX: 0,
      }))
      if (!workflowTemplate.labels) {
        workflowTemplate.labels = {}
      }
      workflowTemplate.labels.admissibilityList = this.admissibility_default_labels
      workflowTemplate.labels.pmEvaluationList = this.pm_evaluation_default_labels
      workflowTemplate.labels.summaryStateList = this.summary_state_default_labels
      if (value === WORKFLOWS.SIMPLIFIED_SGP.name) {
        workflowTemplate.steps = newSteps.filter(o => o.type !== 'Admissibility')
      } else if (value === WORKFLOWS.COMPLETE.name) {
        workflowTemplate.steps = newSteps.filter(o => o.type !== 'ExternalContributions')
      } else if (value === WORKFLOWS.SIMPLE.name) {
        workflowTemplate.steps = newSteps.filter(o =>
          o.type !== 'ExternalContributions'
          && o.type !== 'EvaluationSummary'
          && o.type !== 'EvaluationSummarySubmission',
        )
        workflowTemplate.labels.pmEvaluationList = [
          { name: 'SO - Sans Observation', isActive: true },
          { name: 'AO - Avec Observations', isActive: true },
          { name: 'R - Refusé', isActive: true },
          { name: 'T - Temporisation', isActive: true },
          { name: 'BPE - Bon pour Exécution', isActive: true },
        ]
      } else {
        workflowTemplate.steps = newSteps
      }
      // End of workflow is not shown (but generated on save)
      workflowTemplate.steps = workflowTemplate.steps.filter(o => o.type !== 'EndOfWorkflow')
    },
    removeWorkflowTemplate (workflowTemplate) {
      const index = this.workflowTemplates.findIndex(o => o.id === workflowTemplate.id)
      this.workflowTemplates.splice(index, 1)
    },
    onReorderWorkflowTemplates(workflowTemplate, offset) {
      const workflowTemplates = [...this.workflowTemplates]
      const toMoveWorkflowTemplate = workflowTemplates.find(x => x.id === workflowTemplate.id)
      const swapworkflowTemplate = workflowTemplates.find(x => x.sequence === workflowTemplate.sequence + offset)
      toMoveWorkflowTemplate.sequence += offset
      swapworkflowTemplate.sequence += -offset
      workflowTemplates.sort((a, b) => a.sequence - b.sequence)
      this.$emit('update:workflowTemplates', workflowTemplates)
    },
  },
}
</script>
<template>
  <div class="form-section">
    <h1>{{ $gettext("Workflows") }}</h1>
    <v-form
      ref="form"
      class="workflow-cards-list"
    >
      <transition-group name="list">
        <WorkflowCard
          v-for="(workflowTemplate, index) in workflowTemplates"
          :key="`${index}_workflow_${workflowTemplate.id}`"
          :workflow-template="workflowTemplate"
          :label-types="labelTypes"
          :workflow-templates="workflowTemplates"
          :workflows="workflows"
          :index="index"
          :active-metadata="activeMetadata"
          @remove-workflow-template="removeWorkflowTemplate(workflowTemplate)"
          @change-workflow-template-type="changeWorkflowTemplateType"
          @order-workflow-template="onReorderWorkflowTemplates"
        />
      </transition-group>
    </v-form>
    <div>
      <v-btn
        color="secondary"
        prepend-icon="fas fa-plus"
        rounded
        @click="addWF"
      >
        {{ $gettext('Add a workflow') }}
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.workflow-cards-list {
  display: flex;
  flex-direction: column;
  gap: 1ch;
}
</style>
